@import url("https://fonts.googleapis.com/css2?family=Rokkitt:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap");

// Colors
$primaryColor: #239f87;
$white: #ffffff;
$error: #fe0000;

// Font Family
$primaryFont: "Rokkitt", serif;
$secondaryFont: "Source Sans Pro", sans-serif;

// Weights
$light: 300;
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$extraBold: 800;
$black: 900;

// RESOLUTIONS
$tablet: "(min-width: 768px)";
$tabletLandscape: "(min-width: 992px)";
$desktop: "(min-width: 1200px)";
$desktopLg: "(min-width: 1400px)";

@mixin tablet {
	@media #{$tablet} {
		@content;
	}
}

@mixin tabletLandscape {
	@media #{$tabletLandscape} {
		@content;
	}
}

@mixin desktop {
	@media #{$desktop} {
		@content;
	}
}
@mixin desktopLg {
	@media #{$desktopLg} {
		@content;
	}
}

body {
	background-color: #f5f9ff;
	font-size: 1rem;
	font-weight: 400;
	font-family: "Source Sans Pro", sans-serif;
	line-height: 1.5;
	text-align: left;
}

body {
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: "Source Sans Pro", Arial, sans-serif;
	}
}

a {
	color: rgba(39, 44, 51, 0.7);
	text-decoration: none;
	background-color: initial;

	&:hover {
		color: rgba(6, 7, 8, 0.7);
		text-decoration: underline;
	}
}

.user-structure {
	height: 100vh;
	display: flex;
	@media (max-width: 767px) {
		flex-wrap: wrap;
		min-height: 100%;
	}

	@media (max-width: 767px) {
		width: 100%;
	}
	.info {
		&-header {
			padding: 10px 20px;
			img {
				max-width: 100%;
				height: 35px;
				@media (max-width: 767px) {
					height: auto;
				}
			}
			@include tablet {
				padding: 14px 20px 0;
				img {
					height: auto;
				}
			}
		}
		&-body {
			display: none;
			@include tablet {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				padding: 0 144px;
			}
			img {
				max-width: 100%;
			}

			@include tablet {
				padding: 0 35px;
				height: calc(100% - (56px + 65px - 15px));
			}
			@include tabletLandscape {
				padding: 0 35px;
				height: calc(100% - (56px + 65px));
			}
			h1 {
				font-weight: $bold;
				font-size: 36px;
				line-height: 36px;
				max-width: 517px;
				font-family: $primaryFont;
				color: #00a689;
				margin-bottom: 14px;
				@media (max-width: 991px) {
					font-size: 26px;
					line-height: normal;
				}
			}
			p {
				font-size: 18px;
				font-family: $secondaryFont;
				font-weight: $regular;
				text-align: center;
				line-height: 32px;
				color: #666666;
				margin-bottom: 58px;
				@media (max-width: 991px) {
					font-size: 16px;
					line-height: normal;
				}
				@include tabletLandscape {
					font-size: 20px;
				}
			}
		}
		&-footer {
			display: none;
			@include tablet {
				display: block;
				padding: 0 35px 20px;
			}
			@include tabletLandscape {
				padding: 0 94px 35px;
			}
			ul {
				display: flex;
				margin: 0;
				padding: 0;
				list-style: none;
				align-items: center;
				justify-content: center;
				li {
					& + li {
						margin-left: 50px;
						a {
							position: relative;
							&::before {
								content: "";
								width: 4.24px;
								height: 4.24px;
								left: -27.24px;
								top: 50%;
								position: absolute;
								background: #666666;
								opacity: 0.3;
								transform: rotate(45deg) translateY(-50%);
							}
						}
					}
					a {
						font-weight: 600;
						font-family: $secondaryFont;
						cursor: pointer;
						font-size: 16px;
						line-height: 30px;
						&.fb {
							color: #3b5998;
						}
						&.lk {
							color: #0077b5;
						}
						&.ins {
							color: #1da1f2;
						}
					}
				}
			}
		}
	}
	&--info {
		background-color: #fff;
		width: 50%;
		// @media (max-width: 1200px) {
		//   width: 100%;
		// }

		@media (max-width: 991px) {
			width: calc(100% - 350px);
		}

		@media (max-width: 767px) {
			width: 100%;
			background: #f5f9ff;
		}
	}
	&--form {
		display: flex;
		align-items: center;
		justify-content: center;
		background: #f5f9ff;
		width: 50%;
		// .form-control {
		//   color: #596d8c;
		//   &:focus {
		//     color: #596d8c;
		//   }
		// }
		@media (max-width: 1200px) {
			width: 50%;
			padding: 50px 20px;
		}
		@media (max-width: 991px) {
			width: 350px;
		}
		@media (max-width: 767px) {
			width: 100%;
			padding: 30px 20px;
		}
		.wrap {
			// flex-grow: 1;
			width: 432px;
			@media (max-width: 992px) {
				width: 100%;
			}
			@media (max-width: 767px) {
				width: 70%;
			}
			@media (max-width: 575px) {
				width: 100%;
			}
			label {
				& + .form-control {
					border: none;
					padding: 0;
				}
			}
			.button-group {
				margin-top: 35px;
				.btn {
					width: 50%;
					@media screen and (max-width: 480px) {
						width: auto;
						flex-grow: 1;
					}
				}
			}
			.form-control {
				background-color: #f3f3f3;
			}
			p {
				font-size: 16px;
				line-height: 28px;
				font-family: $secondaryFont;
				color: rgba($color: #6f6f6f, $alpha: 0.85);
				margin: 0;
				@include tablet {
					font-size: 20px;
					line-height: 32px;
				}
				& + p {
					margin-top: 20px;
				}
			}
		}
		.form-link {
			margin-top: 15px;
			a {
				font-family: $secondaryFont;
				font-weight: $semiBold;
				font-size: 16px;
				line-height: 23px;
				color: #6f6f6f;
			}
		}
		.or-seprator {
			position: relative;
			text-align: center;
			margin: 20px 0 14px;
			&::before {
				content: "";
				width: 100%;
				height: 1px;
				background-color: #e0e0e0;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
			span {
				font-size: 20px;
				line-height: 1;
				background-color: #f5f9ff;
				padding: 0 10px;
				position: relative;
				color: #173460;
				font-weight: $regular;
				font-family: $secondaryFont;
			}
		}
		h2 {
			font-size: 24px;
			line-height: 34px;
			// text-align: center;
			font-family: $primaryFont;
			font-weight: $bold;
			color: #2a2a2a;
			margin-bottom: 20px;
			@include tablet {
				font-size: 34px;
				line-height: 44px;
			}
		}
		h3 {
			font-weight: $bold;
			font-family: $secondaryFont;
			margin: 0 0 4px;
			font-size: 15px;
			line-height: 33px;
			color: #2a2a2a;
			@include tablet {
				font-size: 18px;
				line-height: 36px;
			}
		}
		.login-options {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			list-style: none;
			margin: 0;
			padding: 0;
			@media (max-width: 575px) {
				display: block;
			}
			li {
				@media (max-width: 992px) {
					width: 100%;
				}
				& + li {
					margin-left: 20px;
					@media (max-width: 575px) {
						margin-left: 0;
						margin-top: 10px;
					}
				}
				a {
					cursor: pointer;
					// background: linear-gradient(270deg, #f4f4f4 0%, #f4f4f4 100%);
					background-color: #fff;
					border: 1px solid #fff;
					border-radius: 4.8px;
					transition: all ease-in-out 0.3s;
					font-size: 0;
					line-height: 20px;
					font-weight: $regular;
					font-family: $secondaryFont;
					color: #6f6f6f;
					width: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 44px;
					@media (max-width: 1280px) {
						font-size: 14px !important;
						padding: 0 10px !important;
					}
					@media (max-width: 992px) {
						width: 100%;
					}
					img {
						@media (max-width: 992px) {
							margin-right: 10px;
						}
					}
					@include tabletLandscape {
						font-size: 14px;
						width: auto;
						padding: 0 20px;
						img {
							margin-right: 10px;
						}
					}
					@include desktop {
						font-size: 16px;

						img {
							margin-right: 20px;
						}
					}
					&:hover {
						background-color: darken($color: #f4f4f4, $amount: 5%);
						transition: all ease-in-out 0.3s;
					}
				}
			}
		}

		.otp-box {
			margin-bottom: 30px;
			display: block;
			ul {
				margin: 2px 0 4px;
				padding: 0;
				display: flex;
				align-items: center;
				list-style: none;
				li {
					width: 50px;
					& + li {
						margin-left: 20px;
					}
					.form-control {
						text-align: center;
						padding: 0;
						background-color: #fff;
						font-weight: $semiBold;
						font-size: 20px;
						color: #6f6f6f;
					}
				}
			}
			p {
				font-weight: $semiBold;
				font-size: 13px;
				line-height: 36px;
				margin: 0;
				color: #6f6f6f;
				font-family: $secondaryFont;
			}
			.btn-link {
				font-size: 13px;
				color: $error;
				margin: 11px 0;
				line-height: 1;
			}
		}
	}

	.onboarding-info {
		margin-top: 30px;
		p {
			font-size: 16px !important;
			color: #000;
			a {
				color: #000;
				text-decoration: underline;
			}
		}
	}
}

.form-group {
	margin-bottom: 15px;

	label {
		display: block;
		font-family: "Source Sans Pro", sans-serif;
		margin-bottom: 5px;
		font-weight: 700;
		font-size: 13px;
		line-height: 18px;
		text-transform: uppercase;
		color: #393e41;

		sup {
			font-weight: 700;
			font-size: 13px;
			line-height: 1;
			text-transform: uppercase;
			color: #c95c54;
			top: -2px;
		}
	}
}

.primary-form-control {
	height: 40px;
	border-radius: 6px;
	background: linear-gradient(180deg, #fff, #f9fafb);
	display: block;
	width: 100%;
	font-family: "Source Sans Pro";
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	padding: 11px;
	outline: none;
	text-align: left;
	color: #111;
	border: 1px solid #c4cdd5 !important;
	box-sizing: border-box;
	box-shadow: 0 1px 0 rgba(22, 29, 37, 0.05);
	&:focus {
		border-color: #239f87 !important;
		box-shadow: none !important;
	}
}

.input-group {
	position: relative;
	border: none;
}

.input-group-append,
.input-group-prepend {
	display: flex;
}

.input-group .input-group-append {
	right: 12px;
}

.input-group .input-group-append,
.input-group .input-group-prepend {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.input-group .input-group-text {
	padding: 0;
	background-color: initial;
	border-radius: 0;
	border: none;
}

.input-group .input-group-text svg {
	fill: #acb0b5;
}

.button-group {
	display: flex;
	align-items: center;
}

.btn {
	min-width: 50px;
	height: 35px;
	line-height: 20px;
	transition: all 0.3s ease-in-out;
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 700;
	text-align: center;

	&.btn-primary {
		background-color: #239f87 !important;
		border-color: #239f87 !important;
	}
}

.main-content {
	display: flex;
	flex-wrap: wrap;
	height: calc(100vh - 60px);

	.content {
		width: calc(100% - 260px);
		overflow: auto;
		height: 100%;
	}
}

.sidebar {
	height: 100%;
	background: linear-gradient(167.93deg, #1ea087 22.39%, #35838e 87.46%);
	width: 260px;
	padding: 30px 0;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			ul {
				li {
					a,
					button {
						padding-left: 55px;
					}
				}
			}
			&.active {
				& > a,
				button {
					background-color: #00b8be;
				}
			}
			a,
			button {
				font-family: "Source Sans Pro";
				font-style: normal;
				font-weight: 600;
				display: flex;
				margin: 0;
				border-radius: 0;
				width: 100%;
				height: 40px;
				padding: 0 30px;
				justify-content: space-between;
				border: none;
				align-items: center;
				font-size: 14px;
				background-color: transparent;
				line-height: 18px;
				color: #ffffff;

				&.active {
					background-color: #29928a;
					&:hover {
						background-color: #29928a;
					}
				}

				&:active {
					background-color: #00b8be !important;
				}

				svg {
					transition: all ease-in-out 0.3s;
					&.active {
						transform: rotate(90deg);
					}
				}

				&:hover {
					text-decoration: none;
					background-color: #00b8be;
				}
			}
		}
	}
}

.header {
	position: sticky;
	top: 0;
	z-index: 999;
	padding: 0 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	background: #ffffff;
	box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.05);

	p {
		margin: 0 9px 0 0;
		text-align: right;
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		color: #00b8be;
		span {
			font-weight: 600;
			font-size: 14px;
			line-height: 18px;
			color: #1ea087;
			display: block;
		}
	}

	div {
		img {
			width: 40px;
			height: 40px;
			border-radius: 50%;
		}
	}
}

.page-header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	justify-content: space-between;
	.back {
		width: 32px;
		height: 32px;
		background: #fff;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
		display: flex;
		margin-right: 8px;
		align-items: center;
		justify-content: center;
	}
	button {
		padding: 0 20px;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		height: 30px;
		display: flex;
		background: #ffe700;
		border: 1px solid #e9e9e9;
		border-radius: 4px;
		align-items: center;
		color: #35363b;
	}
	h1 {
		font-weight: 400;
		font-size: 28px;
		line-height: 35px;
		margin: 0 0 4px;
		color: #35363b;
	}
	p {
		margin: 0;
		font-weight: 400;
		font-size: 16px;
		line-height: 20px;
		color: #35363b;
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		display: flex;
		align-items: center;
		li {
			display: flex;
			a {
				font-weight: 400;
				font-size: 14px;
				position: relative;
				line-height: 18px;
				letter-spacing: 0.03em;
				color: #535974;
			}

			& + li {
				margin-left: 20px;
				a:before {
					content: ">";
					position: absolute;
					left: -12px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}
}

.table {
	// border: 1px solid #ececec;
	// border-radius: 6px;
	th,
	td {
		vertical-align: middle;
		padding: 10px 27px;
	}
	tbody {
		background-color: #fff;

		p {
			font-weight: 400;
			font-size: 13px;
			line-height: 16px;
			margin: 0;
			color: rgba($color: #000000, $alpha: 0.7);

			span {
				display: block;
				color: #a1a1a1;
			}
		}
	}
	thead {
		th {
			&:first-child {
				border-top-left-radius: 6px;
			}
			&:last-child {
				border-top-right-radius: 6px;
			}
			background: #ececec;
			font-weight: 600;
			font-size: 13px;
			line-height: 16px;
			color: #35363b;
		}
	}
}

.more-actions {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	margin-left: 10px;
	justify-content: center;
	background: #fafafa;
	border: 1px solid #f2f2f2;
	border-radius: 50%;
	position: relative;
	&:not(:hover) {
		.more-actions {
			&_menu {
				display: none;
			}
		}
	}

	&_menu {
		position: absolute;
		top: 0;
		right: 29px;
		width: 100px;
		border: 1px solid #f2f2f2;
		background: #fff;
		border-radius: 3px;

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				font-weight: 600;
				text-align: left;
				font-size: 13px;
				cursor: pointer;
				line-height: 16px;
				margin: 0;
				padding: 10px 15px;
				color: rgba($color: #000000, $alpha: 0.7);

				&:hover {
					text-decoration: underline;
				}

				& + li {
					border-top: 1px solid #f2f2f2;
				}
			}
		}
	}
}

.primary-info {
	display: flex;
	align-items: center;
	p {
		padding-left: 14px;
		font-weight: 600 !important;

		span {
			font-weight: 400 !important;
			margin-top: 2px;
		}
	}
	img {
		width: 48px;
		height: 48px;
		border-radius: 50%;
		object-fit: contain;
		background-color: #fafafa;
	}
}

.custom-select {
	position: relative;
	img {
		pointer-events: none;
		position: absolute;
		right: 14px;
		top: 17px;
	}
	select {
		appearance: none;
	}
}

.upload-wrapper {
	&-row {
		border-bottom: 1px solid #d9d9d9;
		padding: 10px 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		p {
			width: calc(129px + 64px);
			margin: 0;
			font-weight: 600;
			font-size: 13px;
			line-height: 16px;
			color: #3a3e41;

			sup {
				font-weight: 700;
				font-size: 13px;
				line-height: 1;
				text-transform: uppercase;
				color: #c95c54;
				top: -2px;
			}
		}

		span {
			font-weight: 400;
			font-size: 10px;
			line-height: 13px;
			color: #3a3e41;
		}
	}
	h3 {
		font-weight: 600;
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 20px;
		text-transform: uppercase;
		color: #35363b;
	}
}

.custom-upload {
	margin-right: 14px;
	label {
		width: 154px;
		font-weight: 400;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 13px;
		line-height: 16px;
		text-transform: capitalize;
		color: #35363b;
		height: 30px;
		background: #ececec;
		border-radius: 4px;
		transition: all 0.3s ease-in-out;

		&:hover {
			background-color: darken($color: #ececec, $amount: 5%);
		}
	}
	input {
		display: none;
	}
}

.uploaded-file {
	display: flex;
	align-items: center;

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		column-gap: 10px;
		display: flex;
		align-items: center;
		li {
			cursor: pointer;
		}
	}
	.icon {
		overflow: hidden;
		width: 100px;
		margin-right: 12px;
		background: #ececec;
		border-radius: 6px;
		img {
			max-width: 100%;
		}
	}
}

// .upload-wrapper-row {
// 	& > .ImageCropper {
// 		width: calc(100% - 193px);
// 		& > div {
// 			padding: 0;
// 			border: none;
// 			border-radius: 0;
// 			background: transparent;

// 			input {
// 				position: absolute;
// 				left: 0;
// 				opacity: 0;
// 				cursor: pointer;
// 				top: 0;
// 				width: 100%;
// 				z-index: 3;
// 				height: 100%;
// 			}
// 		}
// 	}
// 	.row {
// 		.col-md-6:first-child {
// 			div {
// 				width: 154px;
// 				font-weight: 400;
// 				cursor: pointer;
// 				display: flex;
// 				align-items: center;
// 				justify-content: center;
// 				font-size: 13px;
// 				line-height: 16px;
// 				text-transform: capitalize;
// 				color: #35363b;
// 				height: 30px;
// 				background: #ececec;
// 				border-radius: 4px;
// 				transition: all 0.3s ease-in-out;
// 				position: relative;

// 				&:hover {
// 				}

// 				&::after {
// 					content: "Upload File Here";
// 					position: absolute;
// 					left: 0;
// 					height: 100%;
// 					width: 100%;
// 					display: flex;
// 					align-items: center;
// 					justify-content: center;
// 					text-align: center;
// 					z-index: 1;
// 					top: 0;
// 				}
// 			}
// 		}
// 	}
// }

.list-group-item {
	background-color: #fff;
	border: 1px solid #eee;
	// border-bottom: none;

	// & + .list-group-item {
	// 	margin-top: 1px;
	// }
}
